import TreeModel from "./TreeModel";
const Trees = (props) => {
  return (
    <group {...props}>
      <TreeModel position={[-1, -0.2, 2]} />
      <TreeModel position={[-8, -0.2, 3.5]} />
      <TreeModel position={[6, -0.2, 1.5]} />
      <TreeModel position={[-6.5, -0.2, -2]} />
      <TreeModel position={[-5, -0.2, -6]} />
      <TreeModel position={[-8, -0.2, -8]} />
      <TreeModel position={[1.8, -0.2, 3]} />
      <TreeModel position={[-2.3, -0.2, -1.3]} />
      <TreeModel position={[1, -0.2, 0.25]} />
      <TreeModel position={[5, -0.2, -12]} />
      <TreeModel position={[5.5, -0.2, -3.5]} />
      <TreeModel position={[10, -0.2, -8.5]} />
      <TreeModel position={[9, -0.2, -6]} />
      {/* <TreeModel position={[-2.1, 0.075, 1.2]} /> */}
      {/* <TreeModel position={[1.2, 0.075, 2.5]} /> */}
      <TreeModel position={[0.7, -0.2, -3]} />
      {/* <TreeModel position={[1.5, 0.075, -5]} /> */}
      <TreeModel position={[-2, -0.2, -3.5]} />
      <TreeModel position={[-2, -0.2, -12.1]} />
      <TreeModel position={[0.3, -0.2, -9.5]} />
      <TreeModel position={[0, -0.2, -6.7]} />
      <TreeModel position={[2.6, -0.2, -11.2]} />
      <TreeModel position={[-0.4, -0.2, -14]} />
      <TreeModel position={[2.1, -0.2, -5.1]} />
      <TreeModel position={[3.7, -0.2, -7.5]} />
      {/* <TreeModel position={[-4, 0.075, -8]} /> */}
    </group>
  );
};

export default Trees;
