/* eslint-disable react/jsx-no-target-blank */
import styles from "../css/tempHome.module.css";
import { getSingleDoc } from "../config/firebaseFirestore";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import Aftermovies from "../components/aftermovies";
import Discover from "../components/discover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faEye,
  faGraduationCap,
  faMasksTheater,
  faShoePrints,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import LandingGallery from "../components/landinggallery";
// import typeface from "../assets/bg/typeface.png";
import typefacefinal from "../assets/bg/typefacefinal.png";
import {
  faFacebook,
  faGooglePlay,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import Navbar from "../components/navbar";
import Associate from "../components/associate";
import Loader from "../components/loader";
import { useMediaQuery } from "react-responsive";
const TempHome = ({ switchMode }) => {
  const [loadingdata, setLoadingdata] = useState(true);
  const [aftermovies, setAftermovies] = useState([]);
  const [gallery, setGallery] = useState([]);
  // const [discover, setDiscover] = useState([]);
  const [discoverData, setDiscoverData] = useState([]);
  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 800px)",
  });

  const fetchData = async () => {
    setLoadingdata(true);
    const rawData = await getSingleDoc("WebContents", "appLandingPage");
    let aftermovies = [];
    let gallery = [];
    let discover = [];
    for (let i = 0; i < rawData.data.length; i++) {
      switch (rawData.data[i].Vertical) {
        case "Aftermovies":
          aftermovies.push(rawData.data[i]);
          break;
        case "Gallery":
          gallery.push(rawData.data[i]);
          break;
        case "Discover More":
          discover.push(rawData.data[i]);
          break;
        default:
          break;
      }
    }
    setAftermovies(aftermovies);
    setGallery(gallery);
    // setDiscover(discover);
    setDiscoverData(discover);
    setLoadingdata(false);
  };

  const [showNavbarLink, setShowNavbarLink] = useState(false);

  useEffect(() => {
    fetchData();
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        setLoadingdata(false);
      } else {
        setLoadingdata(true);
      }
    };
    const handleScroll = () => {
      if (window.scrollY >= window.innerHeight) {
        setShowNavbarLink(true);
      } else {
        setShowNavbarLink(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loadingdata) {
    return <Loader />;
  }

  return (
    <div className={styles.landing2d}>
      {isLaptopOrDesktop && (
        <div
          onClick={() => {
            switchMode(true);
          }}
          className={styles.normalMode}
        >
          3D Mode
        </div>
      )}
      {showNavbarLink && (
        <div className={styles.navbarLink}>
          <Navbar />
        </div>
      )}
      <div className={styles.tempHome}>
        <div className={styles.bg}></div>
        <div className={styles.title}>
          <img className={styles.typeface} src={typefacefinal} alt="typeface" />
          <div className={styles.nav}>
            <a href="https://events.antaragni.in" target="_blank">
              Events
            </a>
            <a href="https://ca.antaragni.in" target="_blank">
              CA Program
            </a>
            <li className={`${styles.link} ${styles.dropdown}`}>
              <span
                className={`${styles.pOpensans} ${styles.navbarText} ${styles.dropdownToggle}`}
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Roadtrips
              </span>
              <ul
                className={styles.dropdownMenu}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://bug.antaragni.in/"
                  >
                    Battle Underground
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://synchronicity.antaragni.in/"
                  >
                    Synchronicity
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://comickaun.antaragni.in/"
                  >
                    ComicKaun
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://junoon.antaragni.in/"
                  >
                    Junoon
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://djwar.antaragni.in/"
                  >
                    Dj War
                  </a>
                </li>
                <li>
                  <a
                    className={`${styles.dropdownItem}`}
                    target="_blank"
                    rel="noreferrer"
                    href="https://nationals.antaragni.in/"
                  >
                    Nationals
                  </a>
                </li>
              </ul>
            </li>
            <Link to="/merchandise">Merchandise</Link>
            <Link to="/schedule">Schedule</Link>
            <li className={`${styles.link} ${styles.dropdown}`}>
              <span
                className={`${styles.pOpensans} ${styles.navbarText} ${styles.dropdownToggle}`}
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Gallery
              </span>
              <ul
                className={styles.dropdownMenu}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/brochure">Brochure</Link>
                </li>
                <li>
                  <Link to="/media">Media</Link>
                </li>
              </ul>
            </li>
            <Link to="/Attractions">Star Attractions</Link>
            <Link to="/sponsors">Sponsors</Link>
            <Link to="/coreTeam">Core Team</Link>
            <Link to="/queries">Queries</Link>
          </div>
        </div>
      </div>
      <div className={styles.about}>
        <div className={styles.blockbout}>
          <h1>About Antaragni</h1>
          <p className={styles.aboutpara}>
            Antaragni - The Annual Cultural Festival Of IIT Kanpur Is One Of The
            Largest And Most Anticipated Festivals Throughout Asia. Translated
            Literally To The Fire Within, Everyone Associated Will Feel Inside.
            Continuing Its Rich Legacy Of 57 Years, Antaragni Combines The Zeal
            And Desire Of The Students Of IIT Kanpur And Across India To
            Showcase And Celebrate Their Amazing Cultural Talent As It Has
            Evolved To Be A Platform For Global Cultural Interaction Over The
            Years. It Has Been The Touchstone Of India's College Cultural
            Heritage.
          </p>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon
                  icon={faGraduationCap}
                  style={{ color: "#efc36a" }}
                />
              </div>
              <p>350+</p>
              <p>Colleges</p>
            </div>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon icon={faEye} style={{ color: "#efc36a" }} />
              </div>
              <p>25M+</p>
              <p>Eyeballs</p>
            </div>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon
                  icon={faMasksTheater}
                  style={{ color: "#efc36a" }}
                />
              </div>
              <p>75+</p>
              <p>Events</p>
            </div>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon
                  icon={faSitemap}
                  style={{ color: "#efc36a" }}
                />
              </div>
              <p>50+</p>
              <p>Govt. Org</p>
            </div>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon
                  icon={faBuilding}
                  style={{ color: "#efc36a" }}
                />
              </div>
              <p>150+</p>
              <p>Corporates</p>
            </div>
            <div className={styles.stat}>
              <div className={styles.statsicon}>
                <FontAwesomeIcon
                  icon={faShoePrints}
                  style={{ color: "#efc36a" }}
                />
              </div>
              <p>130K+</p>
              <p>Footfall</p>
            </div>
          </div>
        </div>
      </div>
      <Fade>
        <div className={styles.gallery}>
          {" "}
          {/*actually discover now */}
          <LandingGallery data={discoverData} />
        </div>
      </Fade>
      <Fade>
        <div className={styles.discover}>
          {" "}
          {/*actually gallery now */}
          <Discover data={gallery} />
        </div>
      </Fade>
      <Fade>
        <div className={styles.aftermovies}>
          <h1>Aftermovies</h1>
          <Aftermovies data={aftermovies} />
        </div>
      </Fade>
      <Fade>
        <div className={styles.aftermoviesmobile}>
          <h1>Aftermovies</h1>
          <iframe
            width="560vw"
            height="315vh"
            src="https://www.youtube.com/embed/boF7X5yBEXo?si=OqT1uHt3mORmycxb"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            width="560vw"
            height="315vh"
            src="https://www.youtube.com/embed/qZmtHBF0AKQ?si=EqWn90k_4uj2bMbq"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            width="560vw"
            height="315vh"
            src="https://www.youtube.com/embed/cMoHcuD6vOQ?si=UlLwuRL_5BOKvkLl"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            width="560vw"
            height="315vh"
            src="https://www.youtube.com/embed/hQQLEFmkqwg?si=3KIwzPaHStRPDy40"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <iframe
            width="560vw"
            height="315vh"
            src="https://www.youtube.com/embed/zFtS63KKhvc?si=2LMR0x7g6Ek73Ugp"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </Fade>
      <div className={styles.associate}>
        <Fade>
          <Associate />
        </Fade>
      </div>
      <div className={styles.footer}>
        <div className={styles.dummydiv}>
          <a
            href="https://play.google.com/store/apps/details?id=antaragni.flame"
            className={styles.googleIcon}
          >
            <FontAwesomeIcon icon={faGooglePlay} className={styles.faIcon} />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=antaragni.flame"
            target="_blank"
            className={styles.getTheApp}
          >
            Get The App
          </a>
        </div>
        <div className={styles.social}>
          <div className={styles.socialicons}>
            <a
              target="_blank"
              href="https://www.instagram.com/antaragni.iitkanpur/"
            >
              <FontAwesomeIcon icon={faInstagram} className={styles.faIcon} />
            </a>
          </div>
          <div className={styles.socialicons}>
            <a target="_blank" href="https://twitter.com/antaragni">
              <FontAwesomeIcon icon={faTwitter} className={styles.faIcon} />
            </a>
          </div>
          <div className={styles.socialicons}>
            <a
              target="_blank"
              href="https://www.youtube.com/user/antaragniiitkanpur"
            >
              <FontAwesomeIcon icon={faYoutube} className={styles.faIcon} />
            </a>
          </div>
          <div className={styles.socialicons}>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/antaragni-iit-kanpur/mycompany/"
            >
              <FontAwesomeIcon icon={faLinkedin} className={styles.faIcon} />
            </a>
          </div>
          <div className={styles.socialicons}>
            <a target="_blank" href="https://www.facebook.com/antaragni.iitk/">
              <FontAwesomeIcon icon={faFacebook} className={styles.faIcon} />
            </a>
          </div>
        </div>
        <div className={styles.footertext}>
          <p>© Antaragni'24 IIT Kanpur</p>
        </div>
      </div>
    </div>
  );
};
export default TempHome;
