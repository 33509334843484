import { BrowserRouter, Routes, Route } from "react-router-dom";
import styles from "../css/app.module.css";
import {
  CoreTeam,
  Query,
  Sponsors,
  Gallery,
  Media,
  Story,
  Brochure,
  Attractions,
  Schedule,
  Merch,
  TempHome,
} from "../pages";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
const App = () => {
  const [show3D, setShow3D] = useState(
    useMediaQuery({
      query: "(min-width: 800px)",
    })
  );
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={
              show3D ? (
                <Story switchMode={setShow3D} />
              ) : (
                <TempHome switchMode={setShow3D} />
              )
            }
          ></Route>
          <Route exact path="/gallery" element={<Gallery />}></Route>
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/sponsors" element={<Sponsors />}></Route>
          <Route exact path="/queries" element={<Query />}></Route>
          <Route exact path="/coreteam" element={<CoreTeam />}></Route>
          <Route exact path="/brochure" element={<Brochure />}></Route>
          <Route exact path="/attractions" element={<Attractions />}></Route>
          <Route exact path="/schedule" element={<Schedule />}></Route>
          <Route exact path="/merchandise" element={<Merch />}></Route>
          <Route exact path="/story" element={<Story />}></Route>
          <Route
            exact
            path="*"
            element={<h1>Error 404: Page Not Found</h1>}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
